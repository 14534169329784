import React, { useEffect, useRef } from "react";
import styles from "../../assets/styles/Register.module.css";
import RegisterImg from "../../assets/images/RegisterImg.png";
import crossIcon from "../../assets/images/crossIcon.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../components/baseURL";
import SuccessLogo from "../../assets/images/SuccessTick.png";
import CancleLogo from "../../assets/images/close.png";
import Request from "axios";
import OpenEye from "../../assets/images/OpenEye.png";
import CloseEye from "../../assets/images/CloseEye.png";
import useLoader from "../../hooks/useLoader";
import { useDispatch } from "react-redux";
import moment from "moment";

function RegisterModal({ setShowModal, showModal }) {
  const navigation = useNavigate();
  const fullname = useRef();
  const email = useRef();
  const password = useRef();
  const [successPopup, SetSucessPopup] = useState(true);
  const [successPopupCheck, SetSucessPopupCheck] = useState(false);
  const { setLoader, Loader } = useLoader();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(function () {
      SetSucessPopup(false);
    }, 7000);
  }, []);

  const [showPassword, SetShowPassword] = useState(false);
  const [showConfirmPassword, SetShowConfirmPassword] = useState(false);

  async function createUser() {
    setLoader(true);
    let link =
      showModal === "Login"
        ? `${BASE_URL}login`
        : showModal === "Register"
        ? `${BASE_URL}signup`
        : `${BASE_URL}forgotpassword`;
    let dataToSend =
      showModal === "Login"
        ? {
            email: email.current.value,
            password: password.current.value,
          }
        : showModal === "Register"
        ? {
            fullname: fullname.current.value,
            email: email.current.value,
            password: password.current.value,
            country: document.getElementById("country").value,
            instructor: document.getElementById("instructor").checked,
            updated_at: moment().format("YYYY-MM-DD"),
            role_id: 4,
          }
        : {
            email: email.current.value,
          };
    Request.post(link, dataToSend)
      .then((res) => {
        if (res?.status === 200 && showModal === "Login") {
          // alert("Login Successful");
          console.log("emailss", res.data.fullName);
          localStorage.removeItem("loginUser");
          localStorage.setItem("loginUser", res.data.fullName);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("id", res.data.id);
          dispatch({ type: "USER_ROLE", payload: res.data.role });
          navigation("/");
          setShowModal();
        } else if (res?.status === 200 && showModal === "Register") {
          console.log("Register Successful");
          setShowModal("Login");
          SetSucessPopupCheck(true);
        } else {
          alert(
            showModal === "Login" ? "Login error" : "User Registration Error."
          );
        }
      })
      .catch((err) => {
        console.log(`Error ${showModal} user: `, err);
        alert(`Error ${showModal} user: ${err?.response?.data}`);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  return (
    <div
      className={styles.greyBgMain}
      onClick={(e) => {
        e.currentTarget === e.target && setShowModal();
      }}
    >
      <Loader />
      <div className={styles.mainDiv}>
        <div className={styles.subDivOne}>
          <p className={styles.subDivOneText}>
            elevate your learning to the next level
          </p>
          <img src={RegisterImg} className={styles.RegisterImg} />
        </div>
        <div
          className={
            showModal === "Register" ? styles.subDivTwo : styles.subDivTwo2
          }
        >
          {successPopup && successPopupCheck && (
            <div className={styles.successMsgDiv}>
              <div className={styles.successMsg}>
                <img src={SuccessLogo} />
                <p className={styles.msgText}>
                  Your account is successfully register to Param Academy.
                </p>
                <img src={CancleLogo} onClick={() => SetSucessPopup(false)} />
              </div>
            </div>
          )}
          <div className={styles.newCrossFlex}>
            <div
              className={
                showModal === "Register" ? styles.flexHead : styles.flexHead2
              }
            >
              {showModal === "Register" ? (
                <>
                  <p className={styles.darkBlackText}>Sign Up</p>
                  <p className={styles.greyText}>Create an account</p>
                </>
              ) : showModal === "Login" ? (
                <>
                  <p className={styles.darkBlackText}>Sign In</p>
                  <p className={styles.greyText}>
                    Welcome back! Enter your email and password below to sign
                    in.
                  </p>
                </>
              ) : (
                <>
                  <p className={styles.darkBlackText}>Forgot Password?</p>
                  <p className={styles.greyText}>
                    Enter the email address associated with your account.
                  </p>
                </>
              )}
            </div>
            <div
              onClick={() => {
                setShowModal();
              }}
              className={styles.crossDiv}
            >
              <img src={crossIcon} className={styles.crossImg} />
            </div>
          </div>
          <div className={styles.inputColumns}>
            {showModal === "Register" && (
              <div className={styles.setInGap}>
                <label className={styles.tagNames}>Full name</label>
                <input
                  placeholder="Name"
                  className={styles.nameInput}
                  ref={fullname}
                ></input>
              </div>
            )}
            <div className={styles.setInGap}>
              <label className={styles.tagNames}>Email</label>
              <input
                placeholder="Email"
                className={styles.nameInput}
                ref={email}
              ></input>
            </div>
            {showModal === "Register" ? (
              <>
                <div className={styles.setInGap}>
                  <label className={styles.tagNames}>Password</label>
                  <div className={styles.passwordDiv}>
                    <input
                      input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className={styles.nameInput}
                      ref={password}
                    ></input>
                    <img
                      src={showPassword ? OpenEye : CloseEye}
                      className={styles.eyeIcon}
                      onClick={() => {
                        showPassword
                          ? SetShowPassword(false)
                          : SetShowPassword(true);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : showModal === "Login" ? (
              <>
                <div className={styles.setInGap}>
                  <label className={styles.tagNames}>Password</label>
                  <div className={styles.passwordDiv}>
                    <input
                      input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className={styles.nameInput}
                      ref={password}
                    ></input>
                    <img
                      src={showPassword ? OpenEye : CloseEye}
                      className={styles.eyeIcon}
                      onClick={() => {
                        showPassword
                          ? SetShowPassword(false)
                          : SetShowPassword(true);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {showModal === "Register" && (
              <>
                <div className={styles.setInGap}>
                  <label className={styles.tagNames}>Confirm Password</label>
                  <div className={styles.passwordDiv}>
                    <input
                      input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      className={styles.nameInput}
                    ></input>
                    <img
                      src={showConfirmPassword ? OpenEye : CloseEye}
                      className={styles.eyeIcon}
                      onClick={() => {
                        showConfirmPassword
                          ? SetShowConfirmPassword(false)
                          : SetShowConfirmPassword(true);
                      }}
                    />
                  </div>{" "}
                </div>
                <div className={styles.setInGap}>
                  <label className={styles.tagNames}>Country</label>
                  <select className={styles.registerStyle} id="country">
                    <option value="selectCountry" disabled selected hidden>
                      Select Country
                    </option>
                    <option>CANADA</option>
                    <option>INDIA</option>
                    <option>USA</option>
                  </select>
                </div>
              </>
            )}
            {showModal === "Register" ? (
              <>
                <div className={styles.checkFlex}>
                  <input
                    type="checkbox"
                    id="instructor"
                    className={styles.checkHeightset}
                  />
                  <label className={styles.checkboxText}>
                    Want to become an instructor?
                  </label>
                </div>
              </>
            ) : showModal === "Login" ? (
              <>
                {/* for login */}
                <div className={styles.RememberFlex}>
                  <div className={styles.checkFlex}>
                    <input
                      type="checkbox"
                      id="rememberme"
                      className={styles.checkHeightset}
                    />
                    <label htmlFor="rememberme" className={styles.checkboxText}>
                      Remember Me
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      setShowModal("Forgot");
                    }}
                  >
                    <label className={styles.forgotColor}>
                      {" "}
                      Forgot Password?
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.bottomDivFlex}>
            {showModal === "Register" ? (
              <>
                <button
                  className={styles.signUpBtn}
                  onClick={() => {
                    createUser();
                  }}
                >
                  Sign Up{" "}
                </button>
                <label className={styles.bottomText}>
                  Are you a member?{" "}
                  <a
                    onClick={() => {
                      setShowModal("Login");
                    }}
                    className={styles.hrefSignIn}
                  >
                    {" "}
                    Sign In
                  </a>
                </label>
              </>
            ) : showModal === "Login" ? (
              <>
                <button
                  className={styles.signUpBtn}
                  onClick={() => {
                    createUser();
                  }}
                >
                  Sign In{" "}
                </button>
                <label className={styles.bottomText}>
                  Not a member yet?{" "}
                  <a
                    onClick={() => {
                      setShowModal("Register");
                    }}
                    className={styles.hrefSignIn}
                  >
                    {" "}
                    Sign Up
                  </a>
                </label>
              </>
            ) : (
              <>
                <button className={styles.signUpBtn}>Send </button>
                <label className={styles.bottomText}>
                  Back to{" "}
                  <a
                    onClick={() => {
                      setShowModal("Login");
                    }}
                    className={styles.hrefSignIn}
                  >
                    {" "}
                    Sign In
                  </a>
                </label>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterModal;
